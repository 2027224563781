// export const paymentDetails={
//     gpay:"fcbizopiskn@freecharge",
//     paytm:"fcbizopiskn@freecharge"
// }
// Batham

// export const paymentDetails={
//     gpay:"fcbizf1zwou@freecharge",
//     paytm:"fcbizf1zwou@freecharge"
// }
//Manojnewfc


// export const paymentDetails={
//     gpay:"fcbiznrvpxh@freecharge",
//     paytm:"fcbiznrvpxh@freecharge"
// }
// Shivam 

// export const paymentDetails={
//     gpay:"ombk.aaeo914731dp7ujj586k@mbk",
//     paytm:"ombk.aaeo914731dp7ujj586k@mbk"
// }

export const paymentDetails={
    gpay:"fcbizsvjgun@freecharge",
    paytm:"fcbizsvjgun@freecharge"
}